import { Injectable, signal } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable({ providedIn: "root" })
export class FormConfirmService {
  public formConfirmObjt: BehaviorSubject<formConfirmI> =
    new BehaviorSubject<formConfirmI>({
      askFormSubmit: false,
      isFormDirty: false,
      moveToNextTab: false,
      nextTab: "",
    });

  public resetState() {
    this.formConfirmObjt.next({
      askFormSubmit: false,
      isFormDirty: false,
      moveToNextTab: false,
      nextTab: "",
    });
  }
}

interface formConfirmI {
  isFormDirty: boolean;
  moveToNextTab: boolean;
  askFormSubmit: boolean;
  nextTab: string;
}
